import React from "react";

const BrazilFlag = () => (
    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" viewBox="0 0 512 512" width="24" height="24">
        <mask id="flag-br-mask">
            <circle cx="256" cy="256" r="256" fill="#fff" />
        </mask>
        <g mask="url(#flag-br-mask)">
            <path fill="#6da544" d="M0 0h512v512H0z" />
            <path fill="#ffda44" d="m256 100 212 156-212 156L45 256z" />
            <path fill="#eee" d="M174 221a87 87 0 0 0-7 36l162 50a89 89 0 0 0 14-34c-40-65-119-80-169-52z" />
            <path fill="#0052b4" d="M256 167a89 89 0 0 0-42 11 89 89 0 0 0-40 43 182 182 0 0 1 169 52 89 89 0 0 0-9-59 89 89 0 0 0-78-47zm-44 84a149 149 0 0 0-45 6 89 89 0 0 0 11 41 89 89 0 0 0 120 36 89 89 0 0 0 31-27 151 151 0 0 0-117-56z" />
        </g>
    </svg>
);

export default BrazilFlag;
