import { createContext, useReducer, useEffect } from 'react';

export const themeContext = createContext();

const initialState = { lightMode: false };

// Redutor para controlar o tema
const themeReducer = (state, action) => {
    switch (action.type) {
        case 'toggle':
            const newState = { lightMode: !state.lightMode };
            // Salva o estado atual no localStorage
            localStorage.setItem('lightMode', JSON.stringify(newState.lightMode));
            return newState;
        default:
            return state;
    }
};

export const ThemeProvider = (props) => {
    // Verifica se existe um tema salvo no localStorage
    const savedTheme = localStorage.getItem('lightMode');
    const initial = savedTheme ? { lightMode: JSON.parse(savedTheme) } : initialState;

    const [state, dispatch] = useReducer(themeReducer, initial);

    return (
        <themeContext.Provider value={{ state, dispatch }}>
            {props.children}
        </themeContext.Provider>
    );
};
