import React, { useState, useContext, useEffect, useRef, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom"; 
import { themeContext } from "../Context"; 
import i18n from 'i18next';
import BrazilFlag from "../icons/BrazilFlag";
import USAFlag from "../icons/USAFlag";
import SpainFlag from "../icons/SpainFlag";

const ArrowDown = ({ color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
        <path d="M7 10l5 5 5-5H7z" fill={color} />
    </svg>
);

const LanguageSelector = () => {
    const location = useLocation(); 
    const [selectedLanguage, setSelectedLanguage] = useState('pt-br');
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const navigate = useNavigate(); 

    const theme = useContext(themeContext);
    const lightMode = theme.state.lightMode;

    // Array de idiomas
    const languages = useMemo(() => [
        { label: 'Português', value: 'pt-br', Icon: BrazilFlag },
        { label: 'Inglês', value: 'en-us', Icon: USAFlag },
        { label: 'Espanhol', value: 'es-es', Icon: SpainFlag }
    ], []);

    const handleLanguageChange = (language) => {
        setSelectedLanguage(language.value);
        i18n.changeLanguage(language.value);
        
        // Redireciona para a raiz se for Português, senão para a respectiva URL do idioma
        navigate(language.value === 'pt-br' ? '/' : `/${language.value}`);
        setIsOpen(false);
    };

    useEffect(() => {
        // Verifica o idioma na URL
        const currentPath = location.pathname.split('/')[1]; 
        const currentLanguage = languages.find(lang => lang.value === currentPath);

        if (currentLanguage) {
            setSelectedLanguage(currentLanguage.value);
            i18n.changeLanguage(currentLanguage.value); 
        } else if (selectedLanguage !== 'pt-br') {
            // Só redireciona para 'pt-br' se não houver um idioma configurado
            navigate('/');
        }
    }, [location.pathname, languages, navigate, selectedLanguage]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const currentLanguage = languages.find(lang => lang.value === selectedLanguage);

    return (
        <div className="language-dropdown" ref={dropdownRef} onClick={() => setIsOpen(!isOpen)}>
            <button className="language-button no-hover">
                <currentLanguage.Icon />
                <span style={{ color: lightMode ? 'black' : 'white', marginLeft: '8px' }}>
                    {currentLanguage.value.toUpperCase()}
                </span>
                <ArrowDown color={lightMode ? 'black' : 'white'} />
            </button>
            {isOpen && (
                <div className="dropdown-menu">
                    {languages.map((language) => (
                        <div key={language.value} className="dropdown-item" onClick={() => handleLanguageChange(language)}>
                            <language.Icon />
                            <span style={{ color: lightMode ? 'black' : 'white', marginLeft: '8px' }}>
                                {language.value.toUpperCase()}
                            </span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default LanguageSelector;
