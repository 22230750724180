import WavingHand from "../img/waving-hand.webp";
import GitHub from "../img/github.png";
import LinkedIn from "../img/linkedin.png";
import Memoji from "../img/memoji.webp";
import Resume from "./Resume";
import Download from "../img/download.png";
import { useTranslation } from 'react-i18next';

function Hero() {

    const { t } = useTranslation();
    return (
        <section className="container">
            <div className="intro">
                <div className="i-left">
                    <div className="i-name">
                        <span>
                            {t('greeting')} <img src={WavingHand} width="48" height="48" alt="Gif animado de uma mão acenando" />
                        </span>
                        <h1>{t('my_name_is')} <br /><span className="myname">Tayná Santana</span>, <br /> {t('job_title')}</h1>
                    </div>

                    <div className="i-icons">
                        <a href="https://github.com/olstayna" target="blank" rel="noreferrer" aria-label="GitHub">
                            <img src={GitHub} alt="Logotipo GitHub" loading="lazy" width="87px" height="90px" />
                        </a>
                        <a href="https://www.linkedin.com/in/olstayna/" target="blank" rel="noreferrer" aria-label="LinkedIn">
                            <img src={LinkedIn} alt="Logotipo LinkedIn" loading="lazy" width="87px" height="86px" />
                        </a>
                    </div>
                </div>

                <div className="i-right">
                    <img src={Memoji} alt="Avatar estilizado Memoji de Tayná Santana" />
                    <div style={{ top: '15rem', left: '10rem' }}>
                        <Resume image={Download} />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;
