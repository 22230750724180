import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "./locales/en.json";
import ptTranslation from "./locales/pt.json";
import esTranslation from "./locales/es.json";

const getInitialLanguage = () => {
  const path = window.location.pathname.split('/')[1]; // Captura a primeira parte da URL
  if (path.startsWith('en')) return 'en';
  if (path.startsWith('es')) return 'es';
  return 'pt'; // Padrão para português
};

const resources = {
  pt: {
    translation: ptTranslation,
  },
  en: {
    translation: enTranslation,
  },
  es: {
    translation: esTranslation,
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: getInitialLanguage(), // Define o idioma com base na URL
    fallbackLng: "pt", // Idioma de fallback em caso de erro
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
