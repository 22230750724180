import { FiSun, FiMoon } from "react-icons/fi";
import { themeContext } from "../Context";
import { useContext, useEffect } from "react";

function Toggle() {
    const theme = useContext(themeContext);
    const lightMode = theme.state.lightMode;

    const handleClick = () => {
        theme.dispatch({ type: 'toggle' });
    };

    useEffect(() => {
        document.body.setAttribute('data-theme', lightMode ? 'light' : 'dark');
    }, [lightMode]);

    return (
        <div className="toggle-container">
            <div className="toggle" onClick={handleClick}>
                <FiMoon />
                <FiSun />
                <div className="t-button" style={lightMode ? { right: '2px' } : { left: '2px' }}></div>
            </div>
        </div>
    );
}

export default Toggle;