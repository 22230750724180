import React from 'react';
import { FaTimes, FaExternalLinkAlt } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

const Modal = ({ project, open, onClose }) => {
    const { t } = useTranslation(); 

    const closeModal = (e) => {
        if (e.target.classList.contains('overlay')) {
            onClose();
        }
    };

    if (!open || !project) return null;

    const { img, type, languages, link, key } = project;

    return (
        <div className="overlay" onClick={closeModal}>
            <div className="modalContainer">
                <div className="modalImage">
                    <img src={img} alt="" />
                </div>
                <div className="modalRight">
                    <FaTimes onClick={onClose} className="closeBtn" />
                    <div className="modalContent">
                        <h4>{t(`projects.${key}.title`)}</h4>
                        <p>{t(`projects.${key}.description`)}</p>
                        <ul>
                            <li>
                                <span style={{ fontWeight: 'bold' }}>{t('projects.type')}</span>
                                <span>{type}</span>
                            </li>
                            <li>
                                <span style={{ fontWeight: 'bold' }}>{t('projects.language')}</span>
                                <span>{languages}</span>
                            </li>
                            <li>
                                <span style={{ fontWeight: 'bold' }}>{t('projects.available_at')}</span>
                                <span style={{ maxWidth: '80vw', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                    <a href={link} target="blank"><FaExternalLinkAlt /> {link}</a>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
