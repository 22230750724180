import React, { useContext, useEffect } from 'react';
import "../src/styles/main.css";
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Projects from './components/Projects';
import Professional from './components/Professional';
import Contact from './components/Contact';
import Footer from './components/Footer';
import { themeContext } from './Context';
import './utils/i18n';

function App() {
  const theme = useContext(themeContext);
  const lightMode = theme.state.lightMode;

  useEffect(() => {
    if (lightMode) {
      document.documentElement.style.setProperty('--scrollbar-bg', 'var(--textColor)');
    } else {
      document.documentElement.style.setProperty('--scrollbar-bg', 'var(--borderColor)');
    }
  }, [lightMode]);

  return (
    <div className="App" style={{
      background: lightMode ? 'linear-gradient(to bottom right,#e4e6ff,#fff 40%,#e4ebff)' : '',
      color: lightMode ? 'black' : ''
    }}>
      <Header />
      <Hero />
      <About />
      <Projects />
      <Professional />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;