import React from "react";
import Historic from "../data/professional.json";
import { themeContext } from "../Context";
import { useContext } from "react";
import { useTranslation } from "react-i18next"; // Importando o hook

function Professional() {
    const { t } = useTranslation(); // Usando o hook para acessar as traduções
    const sortedHistoric = Historic.sort((a, b) => b.id - a.id);
    const theme = useContext(themeContext);
    const lightMode = theme.state.lightMode;

    return (
        <section className="container">
            <div className="professional" id="professional">
                <h2>{t("work_experience_title")}</h2>
                <h3>{t("work_experience_subtitle")}</h3>
                <div className="profContainer" style={{ color: lightMode ? 'white' : '' }}>
                    {sortedHistoric.map((experience) => (
                        <div className="histProfessional" key={experience.id}>
                            <div className="leftProfCont">
                                <h4>{t(`experiences.${experience.id}.role`)}</h4>
                                <span>{t(`experiences.${experience.id}.enterprise`)}</span>
                                <p>{t(`experiences.${experience.id}.date`)}</p>
                            </div>
                            <div className="rightProfCont">
                                <p>{t(`experiences.${experience.id}.description`)}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Professional;